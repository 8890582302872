<template>
    <div class="createStepSucceed">
        <div class="createStepSucceed-icon">
            <i class="iconfont">&#xe730;</i>
            <span>创建成功!</span>
        </div>
        <div class="getBack">
            <el-button class="getBackBtn" @click="toStoreCustomer">返回</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "createStepSucceed",
        methods: {
            toStoreCustomer(){
                this.$router.push({
                    path:'/admin/storecustomer'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .createStepSucceed{
        .createStepSucceed-icon{
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            i{
                font-size: 52px;
                color: #20D28C;
            }
            span{
                color: #343434;
                font-size: 24px;
                font-weight: bold;
            }
        }
        .getBack{
            display: flex;
            justify-content: center;
            padding-top: 400px;
            .getBackBtn{
                width: 100px;
                height: 30px;
                background: #ffffff;
                border-color: #1222D8;
                color: #1222D8;
                line-height: 0;
                border-radius: 2px;
            }
        }
    }
</style>